import React, { useEffect } from 'react';

const BackToTop: React.FC = () => {
    useEffect(() => {
        const handleClick = (e: MouseEvent): void => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        const handleScroll = (): void => {
            const button = document.querySelector<HTMLElement>('.back-to-top');
            if (button) {
                if (window.scrollY > 100) {
                    button.style.display = "block";
                } else {
                    button.style.display = "none";
                }
            }
        };

        const button = document.querySelector<HTMLElement>('.back-to-top');
        if (button) {
            button.addEventListener('click', handleClick);
            window.addEventListener('scroll', handleScroll);
            handleScroll();

            return () => {
                button.removeEventListener('click', handleClick);
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <a href="#" className="back-to-top animated" aria-label="Scroll to top" style={{
            background: 'rgba(255, 255, 255, 1)',
            border: '2px solid #003366',
            color: '#003366'
        }}>
            <i className="fa fa-chevron-up" style={{ color: '#003366' }}></i>
        </a>
    );
};

export default BackToTop;   