import React, { useState, useEffect } from "react";
import "./stars.scss";
import "./rocket.scss";
import Typed from "typed.js";
import logo1 from "../img/headshot-color.png";

const Intro = () => {
    const [typed, setTyped] = useState<Typed | null>(null);
    const [logoOpacity, setLogoOpacity] = useState(1);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY;
            const opacity = Math.max(0, 1 - scrolled / 50); // Adjust 200 to control fade speed
            setLogoOpacity(opacity);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const options = {
            strings: [
                "Web Developer",
                "IT Specialist",
                "Enthousiaste Nerd",
            ],
            typeSpeed: 80,
            backDelay: 1100,
            backSpeed: 30,
            loop: true
        };

        const typedInstance = new Typed(".typed-text", options);
        setTyped(typedInstance);

        return () => {
            if (typedInstance) {
                typedInstance.destroy();
            }
        };
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const href = e.currentTarget.getAttribute("href");

        if (href) {
            const element = document.querySelector(href);
            const nav = document.querySelector("nav");

            if (element && nav) {
                const navHeight = nav.offsetHeight;
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;

                window.scrollTo({
                    top: elementPosition - navHeight + 5,
                    behavior: "smooth"
                });
            }
        }
    };

    return (
        <div id="home" className="intro route bg-image background">
            <div id="stars" />
            <div id="stars2" />
            <div id="stars3" />


            <div className="intro-content display-table">
                <div className="table-cell">
                    <div className="container">
                        <img
                            src={logo1}
                            alt="logo"
                            className="mb-4"
                            style={{
                                position: "relative",
                                maxWidth: "200px",
                                borderRadius: "50%",
                                border: "1px solid white",
                                transition: "opacity 0.5s ease-in-out",
                                opacity: logoOpacity,
                            }}
                        />
                        <h1 className="intro-title mb-4">Daniël Schutte</h1>
                        <p className="intro-subtitle">
                            <span className="text-slider-items"></span>
                            <strong className="text-slider">
                                <span className="typed-text"></span>
                            </strong>
                        </p>
                        <p className="pt-5">
                            <a
                                className="btn-lg btn-outline-light btn js-scroll px-4"
                                href="#about"
                                role="button"
                                onClick={handleClick}
                            >
                                Over mij
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Intro;
