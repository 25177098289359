import React from 'react';
import './App.css';
import Intro from 'components/Intro';
import Navbar from 'components/Navbar';
import Portfolio from 'components/Portfolio';
import About from 'components/About';
import Contact from 'components/Contact';
import Preloader from 'components/Preloader';
import BackToTop from 'components/BackToTop';
// CSS imports
import 'normalize.css';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'lightbox2/dist/css/lightbox.min.css';
import './App.css';

// JS imports 
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const App = () => {
  return (
    <>
      <Navbar />
      <Intro />
      <About />
      {/* <Portfolio /> */}
      <Contact />
      <BackToTop />
      <Preloader />
    </>
  );
};

export default App;
