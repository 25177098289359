import React from 'react';

interface ToastProps {
  message: string;
  type: 'success' | 'error';
  show: boolean;
}

const Toast: React.FC<ToastProps> = ({ message, type, show }) => {
  return (
    <div className={`toast-notification ${type} ${show ? 'show' : ''}`}>
      <div className="toast-message">
        {type === 'success' ? '✓' : '✕'} {message}
      </div>
    </div>
  );
};

export default Toast; 