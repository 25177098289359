import React, { useState, useEffect } from "react";
import "./stars.scss";
import "../img/icons/css/ionicons.css";
import Toast from "./Toast";

interface FormData {
    name: string;
    email: string;
    subject: string;
    message: string;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const Contact: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        subject: "",
        message: ""
    });
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (showSuccess || showError) {
            const timer = setTimeout(() => {
                setShowSuccess(false);
                setShowError(false);
            }, 3000);
            
            return () => clearTimeout(timer);
        }
    }, [showSuccess, showError]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setShowSuccess(false);
        setShowError(false);

        try {
            const response = await fetch(`${API_URL}/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setShowSuccess(true);
                setFormData({
                    name: "",
                    email: "",
                    subject: "",
                    message: ""
                });
            } else {
                setShowError(true);
            }
        } catch (error) {
            setShowError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="route bg-image background h-100">
            <div id="stars" />
            <div id="stars2" />
            <div id="stars3" />
            <div className="container d-flex justify-content-center align-items-center h-100">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="contact-mf">
                            <div id="contact" className="box-shadow-full">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title-box-2">
                                            <h5 className="title-left">Let's connect!</h5>
                                        </div>
                                        <div>
                                            <form
                                                action="https://formspree.io/xdoeonlo"
                                                method="POST"
                                                className="contactForm"
                                                onSubmit={handleSubmit}
                                            >
                                                <div id="sendmessage">
                                                    Je bericht is verstuurd. Bedankt!
                                                </div>
                                                <div id="errormessage"></div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                className="form-control"
                                                                id="name"
                                                                placeholder="Je naam"
                                                                data-msg="Vul je naam in"
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="validation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-group">
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="email"
                                                                id="email"
                                                                placeholder="Je email"
                                                                data-rule="email"
                                                                data-msg="Vul een geldig emailadres in"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="validation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="subject"
                                                                id="subject"
                                                                placeholder="Onderwerp"
                                                                data-msg="Vul een onderwerp in"
                                                                value={formData.subject}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="validation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-group">
                                                            <textarea
                                                                className="form-control"
                                                                name="message"
                                                                rows={5}
                                                                data-rule="required"
                                                                data-msg="Vul een bericht in"
                                                                placeholder="Bericht"
                                                                value={formData.message}
                                                                onChange={handleChange}
                                                            ></textarea>
                                                            <div className="validation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-dark"
                                                            disabled={isSubmitting}
                                                        >
                                                            Verstuur bericht
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="more-info pt-5">
                                            <p className="lead">
                                                Ben je geïnteresseerd in wat ik voor jou en je onderneming kan betekenen?
                                                Heb je een probleem waarvan je denkt dat het met IT opgelost kan worden?
                                                Of wil je gewoon even hoi zeggen? <br />
                                                Je mag altijd contact met me opnemen via het contactformulier! Je kan me ook bereiken op één van de kanalen hieronder.
                                            </p>
                                        </div>
                                        <div className="socials">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="https://www.linkedin.com/in/daniel-schutte/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        aria-label="LinkedIn"
                                                    >
                                                        <span className="ico-circle">
                                                            <i className="ion-social-linkedin"></i>
                                                        </span>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a
                                                        href=""
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        aria-label="Instagram"
                                                    >
                                                        <span className="ico-circle">
                                                            <i className="ion-social-instagram"></i>
                                                        </span>
                                                    </a>
                                                </li> */}
                                                <li>
                                                    <a
                                                        href="https://wa.me/31682962983"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        aria-label="X"
                                                    >
                                                        <span className="ico-circle">
                                                            <i className="ion-social-whatsapp"></i>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="copyright-box">
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <Toast 
                show={showSuccess}
                type="success"
                message="Je bericht is verstuurd. Bedankt!"
            />
            <Toast 
                show={showError}
                type="error"
                message="Er is iets fout gegaan. Probeer het later opnieuw."
            />
        </section>
    );
};

export default Contact;