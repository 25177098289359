import React, { useState, useEffect } from "react";
import headshot from "../img/headshot-2.jpg";
import Skills from "./Skills";

interface AboutContent {
    id: string;
    content: string;
}

const About: React.FC = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const about_me: AboutContent[] = [
        {
            id: "first-p-about",
            content: "Ik ben een enthousiaste nerd met een passie voor elke vorm van technologie. In mijn vrije tijd varieert dat van muziek en fotografie tot auto's en Raspberry Pi's. Op professioneel vlak ben ik een breed ervaren IT specialist. Ik heb aan meerdere projecten gewerkt als full-stack software engineer, CI/CD specialist en testanalist."
        },
        {
            id: "second-p-about",
            content: "Het werk dat ik aflever dient voor mij in ieder geval één belangrijk doel; het probleem van de klant oplossen. Om tot een oplossing te komen ga ik graag creatief en open-minded te werk en neem ik alle mogelijkheden en wensen in mijn overweging mee."
        },
    ];

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showSkillsWithText = windowWidth < 768 || windowWidth >= 992;

    return (
        <section id="about" className="about-mf sect-pt4 route">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="box-shadow-full p-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="about-me">
                                        <div className="title-box-2">
                                            <h5 className="title-left">Over mij</h5>
                                        </div>
                                        {about_me.map(content => (
                                            <p className="lead" key={content.id}>
                                                {content.content}
                                            </p>
                                        ))}
                                        {showSkillsWithText && <Skills />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="row">
                                        <div className="col-12 col-md-8 col-lg-10 col-xl-8 col-xxl-7" style={{ margin: "0 auto" }}>
                                            <div className="about-img" style={{ textAlign: "center" }}>
                                                <img className="img-fluid rounded b-shadow-a m-0" src={headshot} alt="me" />
                                            </div>
                                        </div>
                                    </div>
                                    {windowWidth >= 768 && windowWidth < 992 && <Skills />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;