import React, { useEffect, useState } from "react";
import logo2 from "../img/headshot-bw.png";

const Navbar: React.FC = () => {
    const [showNavbar, setShowNavbar] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = (): void => {
            const navbar = document.querySelector(".navbar-expand-md");
            if (navbar) {
                if (window.scrollY > 0) {
                    setShowNavbar(true);
                    navbar.classList.add("navbar-reduce");
                    navbar.classList.remove("navbar-trans");
                } else {
                    setShowNavbar(false);
                    navbar.classList.add("navbar-trans");
                    navbar.classList.remove("navbar-reduce");
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const href = e.currentTarget.getAttribute("href");

        if (href) {
            const element = document.querySelector(href);
            const nav = document.querySelector("nav");

            if (element && nav) {
                const navHeight = nav.offsetHeight;
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;

                window.scrollTo({
                    top: elementPosition - navHeight + 5,
                    behavior: "smooth"
                });
            }
        }
    };

    return (
        <nav className="navbar navbar-b navbar-trans navbar-expand-md fixed-top p-0" style={{ height: "80px" }} id="mainNav">
            <div className="container">
                <a className="navbar-brand js-scroll" href="#page-top">
                    {showNavbar && (
                        <img
                            src={logo2}
                            alt="logo"
                            style={{
                                position: "relative",
                                maxHeight: "70px",
                                borderRadius: "50%",
                                border: "1px solid white",
                                transition: "all 0.5s ease-in-out",
                            }}
                        />
                    )}
                </a>
                {showNavbar && <div>
                    <ul className="navbar-nav ml-auto">
                        <a
                            className="btn btn-outline-dark btn js-scroll px-4"
                            href="#contact"
                            role="button"
                            onClick={handleClick}
                        >
                            Contact
                        </a>
                    </ul>
                </div>}
            </div>
        </nav>
    );
};

export default Navbar;
