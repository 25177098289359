import React from 'react';

interface Skill {
    name: string;
    category: 'frontend' | 'backend' | 'tools' | 'other';
}

const Skills: React.FC = () => {
    const skills: Skill[] = [
        { name: 'React', category: 'frontend' },
        { name: 'TypeScript', category: 'frontend' },
        { name: 'JavaScript', category: 'frontend' },
        { name: 'HTML/CSS', category: 'frontend' },
        { name: 'Node.js', category: 'backend' },
        { name: 'Python', category: 'backend' },
        { name: 'Git', category: 'tools' },
        { name: 'Docker', category: 'tools' },
        { name: 'CI/CD', category: 'tools' },
        { name: 'Web Development', category: 'other' },
        { name: 'Automation', category: 'other' },
        { name: 'Artificial Intelligence', category: 'other' }
    ];

    const getCategoryColor = (category: string): string => {
        switch (category) {
            case 'frontend':
                return '#A000A0'; // Darker Pink
            case 'backend':
                return '#008B8B'; // Darker Cyan
            case 'tools':
                return '#4B0082'; // Darker Purple
            default:
                return '#003366'; // Darker Blue
        }
    };

    return (
        <div className="skills-container">
            <div className="skills-pills">
                {skills.map((skill, index) => (
                    <span
                        key={index}
                        className="skill-pill"
                        style={{
                            border: `2px solid ${getCategoryColor(skill.category)}`,
                            backgroundColor: "white",
                            color: "#1e1e1e",
                        }}
                    >
                        {skill.name}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default Skills; 