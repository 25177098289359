import React, { useEffect, useState } from 'react';

const Preloader: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    if (!isLoading) {
        return null;
    }

    return (
        <div id="preloader">
            <div id="loader" />
        </div>
    );
};

export default Preloader;